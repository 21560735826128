<template>
  <div class="content-wrapper">
    <div class="work main-content">

      <div class="page-header">
        <h1>Projects Timetable</h1>
      </div>

      <div class="timetable">
        <vue-cal :time="false" hide-weekends style="height: 500px"
        :disable-views="['years', 'year', 'month', 'day']"
        :events="events" />
      </div>

    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import VueCal from 'vue-cal';
import 'vue-cal/dist/vuecal.css';
import Common from '@/lib/common';
import Work from '@/lib/work';

export default {
  name: 'HrTimeTable',
  components: {
    VueCal,
  },
  data(){
    return {
      events: [],
    }
  },
  mounted(){
    this.loadAllWorks();
  },
  methods:{
    formatDateTime(timestamp, format){
      const date = Common.formatDateTime(timestamp, format);
      return date;
    },
    async loadAllWorks(){
      const loginInfo = Common.getLoginInfo();
      try{
        //const events = [];
        const works = await Work.loadAllWorks(this.apiUrl, loginInfo);
        for(let i = 0; i < works.length; i++){
          const work = works[i];
          const deadlineStart = this.formatDateTime(work.deadline_start, 'YYYY-MM-DD');
          const deadlineEnd = this.formatDateTime(work.deadline_end, 'YYYY-MM-DD');

          const event = {
            start: deadlineStart,
            end: deadlineEnd,
            title: work.work_name,
            content: `<div class="deadline">
            Deadline: ${deadlineStart} - ${deadlineEnd}
            </div>`,
          };
          this.events.push(event);
        }
      }catch(err){
        console.log(err);
      }
    },
    getEmployeeNumbers(work){
      const baseNumber = 1;
      const employeeNumber = work['work_members'].length + baseNumber;
      return employeeNumber;
    },
  },
  computed: mapState({
   apiUrl: state => state.apiUrl,
 }),
}
</script>

<style lang="scss">
.view-toolbars{
  display: flex;
  .fitler{
    flex: 1;
  }
  .el-icon-plus{
    font-size: 1.2em;
  }
}
.vuecal__event{
  border-bottom: 1px solid #ddd;
  background-color: #FDAA5E;
  color: white;
  &:last-child{
    border: 0;
  }
}
.vuecal__event-title{
  margin-top: 30px;
}
.work-allocation-table{
  margin-top: 50px;
}
</style>
